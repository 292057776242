import React from "react";
import {
  Card,
  Button,
  Heading,
  Image,
  Layout,
  Cell,
  Text,
} from '@wix/design-system';

import Colissimo1 from "../../../assets/images/colissimo-1.png";
import Colissimo2 from "../../../assets/images/colissimo-2.png";
import Colissimo3 from "../../../assets/images/colissimo-3.png";
import Colissimo4 from "../../../assets/images/colissimo-4.png";

class Intro extends React.Component {

  render() {
    return (
      <Layout cols={12} className="introduction">
        <Cell span={12}>
          <Card>
            <Card.Content>
              <Text>
                <Heading size="medium">{I18n.t('configs.intro.heading1')}</Heading>
                <p class="text-intro">
                  {I18n.t('configs.intro.text_intro1')}<br/>
                  {I18n.t('configs.intro.text_intro2')}
                </p>
                {/* <p class="colissimo-link">
                  <Link url="https://www.colissimo.entreprise.laposte.fr/fr/possibilites" external>
                    {I18n.t('configs.intro.colissimo_link')}
                  </Link>.
                </p> */}
                <br/>
                <div class="colissimo-step">
                  <Heading size="medium">
                    <Image src={Colissimo1} width="16" transparent /> {I18n.t('configs.intro.heading_step1')}
                  </Heading>
                  <p class="step-text">{I18n.t('configs.intro.text_step1')}</p>
                </div>
                <Button skin="inverted" id="colissimo_link_button" as="a" href="https://www.colissimo.entreprise.laposte.fr/" target="_blank">{I18n.t('configs.intro.button_step1')}</Button>
                <br/><br/>
                <div class="colissimo-step">
                  <Heading size="medium">
                  <Image src={Colissimo2} width="16" transparent /> {I18n.t('configs.intro.heading_step2')}
                  </Heading>
                </div>
                <br/>
                <div class="colissimo-step">
                  <Heading size="medium">
                    <Image src={Colissimo3} width="16" transparent /> {I18n.t('configs.intro.heading_step3')}
                  </Heading>
                  <p class="step-text">{I18n.t('configs.intro.text_step3')}</p>
                </div>
                <br/>
                <div class="colissimo-step">
                  <Heading size="medium">
                    <Image src={Colissimo4} width="16" transparent /> {I18n.t('configs.intro.heading_step4')}
                  </Heading>
                  <ul>
                    <li>{I18n.t('configs.intro.li1_step4')}</li>
                    <li>{I18n.t('configs.intro.li2_step4')}</li>
                    <li>{I18n.t('configs.intro.li3_step4')}</li>
                    <li>{I18n.t('configs.intro.li4_step4')}</li>
                    <li>{I18n.t('configs.intro.li5_step4')}</li>
                  </ul>
                </div>
              </Text>
            </Card.Content>
          </Card>
        </Cell>
      </Layout>
    );
  }
}

export default Intro